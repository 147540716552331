body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rccs__chip { 
  /* display: none; */
}

.customCard {
  position: relative;
}

.customCard .rccs__chip {
  background-image: var(--img-url);
  width: 100%;
  height: 28px;
}

.customCard.clickable .rccs__card {
  cursor: pointer;
} 

.customCardNew {
  width: 290px;
  height: 182.873px;
  border-radius: 14.5px;
  border: 4px #a3a3a3 dashed;;
  /* background-color: red; */
  margin-left: auto;
  margin-right: auto;

  cursor: pointer;
}

.customCardNew::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 64px;
  transform: translateX(-50%) translateY(-50%);
  background-color: #d1d1d1;
  border-radius: 14.5px;
}
.customCardNew::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 64px;
  height: 8px;
  transform: translateX(-50%) translateY(-50%);
  background-color: #d1d1d1;
  border-radius: 14.5px;
}

.rccs__card--visa .rccs__card__background, .rccs__card--visaelectron .rccs__card__background {
  background: linear-gradient(25deg, #a3a3a3, #33444b) !important;
}